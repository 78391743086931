import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DesktopNavigation, { travelData } from '../../navigation/DesktopNavigation';

const TravelPageTemplate = () => {
  const { country } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const data = travelData[country];


  if (!data) {
    return <div>No data found for this country</div>;
  }
  const openModal = (image) => {
    setSelectedImage(image);
    // Track the event when an image is enlarged
    // console.log(image)
  };
  const closeModal = () => {
    setSelectedImage(null);
  };

  
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '16px',
      fontFamily: 'Arial, sans-serif',
    },
    topImageContainer: {
      position: 'relative',
      marginBottom: '24px',
    },
    topImage: {
      width: '100%',
      height: '250px',
      objectFit: 'cover',
    },
    overlayText: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      color: '#fff', 
      fontWeight: 'bold', 
    },
    overlayTextContent: {
      color: 'white',
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    titleSection: {
      marginBottom: '24px',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    description: {
      lineHeight: '1.5',
      color: '#000', 
    },
    imageGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '16px',
    },
    gridImage: {
      width: '100%',
      objectFit: 'cover',
    },
  };

  return (
    <div className="layout">
      <div className="sidebar">
        <DesktopNavigation />
      </div>
      <div>
      <div>
        <div style={styles.topImageContainer}>
          <img src={data.mainImage} alt="Main Image" style={styles.topImage} />
          <div style={styles.overlayText}>
            <p >{data.claim}</p>
          </div>
        </div>
        <div style={styles.titleSection}>
          <h1 style={styles.title}>{data.title}</h1>
          <p style={styles.description}>{data.description}</p>
        </div>

        <div style={styles.imageGrid}>
          {data.gridImages.map((src, index) => (
            <img 
              key={index}
              src={src}
              alt={`Grid Image ${index + 1}`}
              style={styles.gridImage}
              onClick={() => openModal(src)}
            />
          ))}
          {selectedImage && (
            <div className="desktopModal-overlay">
              <div className="desktopModal-content">
                <button className="desktopModal-close" onClick={closeModal}>
                  &times;
                </button>
                {selectedImage && (
                  <>
                    <img src={selectedImage} 
                    alt={`selectedImage`} className="desktopModal-image" />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
      </div>
  );
};

export default TravelPageTemplate;
