import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer, About, Work, Contact, Terms, NotFound, firebase, TrialWork, DesktopTerms, DesktopAbout, DesktopContact } from './components/common/';
import TravelPageTemplate from './components/common/work/travelPageTemplate/TravelPageTemplate';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', checkScreenWidth);
    checkScreenWidth();

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  return (
    <Router>
      {isMobile ? (
        <Header />
      ) : (
        <></>
      )}
      <Routes>
        <Route path="/" element={isMobile ? <Work /> : <TrialWork />} />
        <Route path="/photoLevs" element={isMobile ? <About /> : <DesktopAbout />} />
        <Route path="/contact" element={isMobile ? <Contact /> : <DesktopContact />} />
        <Route path="/Terms" element={isMobile ? <Terms /> : <DesktopTerms />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/TrialWork" element={<TrialWork />} />
        <Route path="/:country" element={<TravelPageTemplate />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;