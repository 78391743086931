import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './DesktopNavigation.css'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

export const travelData = {
  Singapore: {
    title: "Singapore - 2024",
    claim: `"Without darkness, how can we dream of the day" - Sharon Chin`,
    description: "Singapore, a city-state with a rich and unique history, was born from the jungle and has grown into a vibrant multi-racial, ethnic, and religious metropolis. Once a jewel of the former British Colonial Empire, Singapore was founded by Sir Stamford Raffles as a trading post for the East India Company. The country is unique in its recognition and celebration of its former ties to the British Empire. In 1823, Raffles introduced Regulation No. V, which was Singapore’s first piece of labor legislation, prohibiting slavery and regulating debt bondage. Although Raffles lacked the full authority to enforce it, the regulation set an important precedent for equality. In contrast to the subjugation and brutality experienced under Japanese rule, Singapore reflects positively on the relative peace and innovation brought by British governance, which served as a foundation for the strict laws and customs that have contributed to its prosperity. Singapore stands as a true testament to how effective and efficient governance can lead to individual freedom and national success.",
    mainImage: "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4128.jpg?alt=media&token=9af2c023-3b73-410f-b7c6-d4477de25116",
    gridImages: [
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4581.jpg?alt=media&token=50620af8-5dc3-4d3d-a32d-2634220acb1a",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4663.jpg?alt=media&token=84bfd165-d120-4d45-9db1-4cc8d461f738",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4408.jpg?alt=media&token=5ac5f542-bd0c-4696-ad9a-fe0e7e32f29a",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4472.jpg?alt=media&token=103a7057-e428-435a-b8b1-15de85903d05",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4505.jpg?alt=media&token=01a52956-49da-4157-8672-9ee25509aa2d", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4674.jpg?alt=media&token=44f47ef4-1f12-4171-b777-809dc778ee21", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4699.jpg?alt=media&token=b3639d5c-8d60-4d75-96f7-c49a182a356c", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4714.jpg?alt=media&token=5c76e117-01c4-4c59-8494-c18f158ed7b6", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4722.jpg?alt=media&token=8a39bde9-e041-4271-8b6b-be0dd22c2d74", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4728.jpg?alt=media&token=1ab3a9e6-d726-4620-baf2-7bce3ee22046",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4744.jpg?alt=media&token=a211bb8b-01ef-4c9b-94d4-e7ebf9c61473", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4787.jpg?alt=media&token=99e059ca-b824-4d31-9f3e-e14504467e4d", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4805.jpg?alt=media&token=0bd3b47c-15e4-4b24-9217-06856d72e9c5", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4809.jpg?alt=media&token=b2ece60e-f578-4627-88b0-572f4dd0be05",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4820.jpg?alt=media&token=a9c75511-3cfc-4e68-bc60-182846327466", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4903.jpg?alt=media&token=041a73e7-89c6-4cbf-8e55-39cc98cbf2f1",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4996.jpg?alt=media&token=86c24775-3208-45bc-b662-101b288c9ad9", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF5034.jpg?alt=media&token=066e6bf6-52c3-41fa-a2f5-832cc01ab237", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF5096.jpg?alt=media&token=d590cc4b-332e-4621-977b-cb79d14f5236", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF5120.jpg?alt=media&token=b570ac81-ebfb-4169-af03-a5f68b2c5d89", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF5128.jpg?alt=media&token=1151f1cf-b9a7-4396-adc4-cd0d0382046a", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF5176.jpg?alt=media&token=543f516f-b6ad-4da7-b20f-e3b0441b2d54", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4107.JPG?alt=media&token=b956b497-b7ee-4a57-9974-47caa054415f", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4096.JPG?alt=media&token=f267d681-7114-49f9-a5c3-14c905820ae4",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4101.jpg?alt=media&token=3695c241-0efc-4053-bb1a-d3e01f8f8874", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4226.jpg?alt=media&token=1ec3612a-e0a0-42be-99ea-e106b4dae341", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4286.jpg?alt=media&token=ed4116dc-a001-43fe-b3da-331861cb0513", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4269.jpg?alt=media&token=5f5775e4-4799-4104-bcea-c9c729635907", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4176.jpg?alt=media&token=4e640f52-c496-43cf-99e9-09f674e1d3c8", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4041.jpg?alt=media&token=c35774f1-42ff-4cae-9e3f-2674bd590c73", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4210.jpg?alt=media&token=3a330a06-86ba-4d13-afad-3ecce2b6ee79", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4011.jpg?alt=media&token=267c71ed-1f64-47ba-9d2c-9a63893cd4f4", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4225.jpg?alt=media&token=3aaef94f-e431-410e-8539-065f54e7f48a", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4129.jpg?alt=media&token=012850bf-cedd-4687-873d-6464335179d6", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4128.jpg?alt=media&token=9af2c023-3b73-410f-b7c6-d4477de25116", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4134.jpg?alt=media&token=3d21d0b1-6efc-4461-8238-e73dac29afa6", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4028.jpg?alt=media&token=0dd5ca1e-1701-40b3-bf5e-903128d99e20", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Singapore%2FDSCF4322.jpg?alt=media&token=9e26b40b-e734-4673-878d-739ffb521f0d"
    ]
  },
  Taomina: {
    title: "Taormina, Italy - 2024",
    claim: `"Shed your prior misconceptions - for a place evolves."`,
    description: `Sicily — where the world opened up, no longer restricted by borders. Travel is free, and the era of working 12-hour days from a laptop balanced on a cardboard box is a thing of the past. It is here where the world became digital and quantized. In the years that followed, the world expanded, yet for many, life’s physical radius shifted only slightly, as if moving an inch. To this dilemma, paradise offers an answer, and Taormina, Italy, is exactly that. The town's orange, pink, and terracotta hues dot the mountainous skyline, a product of great civilizations: Greek, Roman, and Arabic. Goethe, after visiting Taormina, remarked on its beauty. Mount Etna, an active volcano and one of the few remaining across the Sicilian skyline, looms in the distance. From time to time, I find myself gazing up at this marvel of nature. A ticking time bomb, perhaps? As the light from the setting sun interacts with the smoke and vapors it releases, the evening sky transforms into a fiery red, with dramatic shadows cast by the clouds. Taormina, a place of "come stai?" and "Vorrie una cola," is best experienced not just for a weekend, but for as long as one can. Wander its trails, explore the ancient Roman Theatre, and embrace the slower pace of life.`,

    mainImage: "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3115.jpg?alt=media&token=9cfa75bf-7d80-4c9d-9822-47a765ca756e",
    gridImages: [
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF2776.jpg?alt=media&token=30b203e8-5ada-4551-a4f1-c0b69e90d286", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF2893.jpg?alt=media&token=f6639480-c2d9-47a9-9d33-45821ee8e690", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF2910.jpg?alt=media&token=d47c56ed-b090-43f0-b9fb-188c391fb0eb", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3039.jpg?alt=media&token=176bdd94-3267-4bd3-9aa5-ea1e21ecced2", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3115.jpg?alt=media&token=9cfa75bf-7d80-4c9d-9822-47a765ca756e", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3205.jpg?alt=media&token=9e257f9e-30f6-4195-91fa-6459f62bb97f", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3342.jpg?alt=media&token=e1ae2021-6134-4e39-a679-054164395337", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3344.jpg?alt=media&token=983f8981-4f40-4eb1-a569-78ab88586703", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3406.jpg?alt=media&token=c2d19383-cdb5-4ddf-95de-a5e0c62c84e9", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3426.jpg?alt=media&token=1d2dafce-9eed-4340-a325-e0aa2f907602", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3428.jpg?alt=media&token=879759f1-bce3-470e-96d4-4dd7365d8b74", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3453.jpg?alt=media&token=9221688e-0377-4d9d-a6b6-2737feb945ec", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3461.jpg?alt=media&token=2d1430a2-f898-4c88-8eae-55c16bbdd76b", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3476.jpg?alt=media&token=97a6f1f4-8e73-4c0c-88a7-7d2b4e9010a3", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3484.jpg?alt=media&token=a11427f6-6bb1-4746-9a19-a808c39e5aa1", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3516.jpg?alt=media&token=d2128543-fa26-4c37-9e9c-afa882d47e7c", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3534.jpg?alt=media&token=8ff3f5fa-0615-4551-b860-6fade2d359bb", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3538.jpg?alt=media&token=6b6f7a59-fbb0-4f6f-8108-e7360da6bfb4", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3561.jpg?alt=media&token=d0de81df-aa3b-47af-a55a-01f0cc99e18c", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3595.jpg?alt=media&token=27c63248-730c-4c9b-b6e3-78b7452abbe0",
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3601.jpg?alt=media&token=e8011439-5a6f-475f-a9f7-7d7c1cf99dd2", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3609.jpg?alt=media&token=bf7b5beb-df58-49aa-9a87-26579ef9ab9a", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3671.jpg?alt=media&token=9cf5401e-35b1-47cc-9ae6-81cd70016bc4", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3692.jpg?alt=media&token=b7394e20-84f7-49d2-8f0c-3402f9b69931", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3735.jpg?alt=media&token=db43e11f-36b5-48fc-abb6-d8f1da263867", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3781.jpg?alt=media&token=b3175485-c127-4760-aa04-c739fae05835", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3866.jpg?alt=media&token=e1fa3a45-83f3-4085-b7ec-d24854daf7a0", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3875.jpg?alt=media&token=c2c16242-6f0a-452e-895e-a04d314febd6", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3896.jpg?alt=media&token=cc864427-c33f-4d21-9c89-1db3477624c5", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3912.jpg?alt=media&token=acac1e59-64dd-4102-b3f6-6ca75e43a482", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3931.jpg?alt=media&token=980f10e6-fff8-4f49-8c8f-68d6cfdf6b60", 
      "https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/Taormina%2FDSCF3938.jpg?alt=media&token=a5c6f68f-d653-42a0-9a01-b0d9405da496"
    ]
  },
};

const DesktopNavigation = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    console.log('is selected', isDropdownOpen)
  
    return (
      <nav>
        <ul className="sideBar">
          <h2 className="Header">
            <a href="/" className="sideBar">photoLevs.</a>
          </h2>
          <li className="sideBar"><Link to='/' className="sideBar">Home</Link></li>
          <li className="sideBar dropdown">
          <button onClick={toggleDropdown} className="dropbtn sideBar">
            Blog 
            <span className="arrow"> 
            {isDropdownOpen ? (
              <MdKeyboardArrowDown className="w-5 h-5" />
              ) : (
                <MdKeyboardArrowRight className="w-5 h-5" />
              )}
            </span>
          </button>
          {isDropdownOpen && (
          <>
            {Object.keys(travelData).map((country) => (
              <li key={country} className="sideBar dropdown-item">
                <Link to={`/${country}`} className="sideBar">- {country}</Link>
              </li>
            ))}
          </>
        )}
        </li>
          <li className="sideBar"><Link to='/photoLevs' className="sideBar">About</Link></li>
          <li className="sideBar"><Link to='/contact' className="sideBar">Contact me</Link></li>
          <li className="sideBar"><Link to='/Terms' className="sideBar">Terms</Link></li>
          <li className="sideBar">
          </li>
        </ul>
      </nav>
    );
  };
  
  export default DesktopNavigation; 